
import { defineComponent, watchEffect, ref } from "vue";
import jwtDecode from "jwt-decode";
import { useRoute, useGetAll, useGetOne } from "@/helpers";
import { get, chain } from "lodash";
import { Avatar3 } from "@/uikit";
import { ERoutesName } from "@/router";
import { REGION_FRAGMENT } from "@/graphql";
import { TRegion } from "@/interface";
import { Loading } from "@/components";
import HomeCarousel from "./components/home-carousel.vue";

export default defineComponent({
  components: { Loading, HomeCarousel, Avatar3 },
  setup(props, context: any) {
    const regionId = ref<string>();
    /* -------------------------------------------------------------------------- */
    /*                                  Get token                                 */
    /* -------------------------------------------------------------------------- */
    const { query, push } = useRoute();
    const token = get(query, "token", "");
    if (!token) {
      push("./no-data-result");
    } else {
      localStorage.setItem("x-token", String(token));
      const res = jwtDecode(String(token));
      const _regionId = get(res, "regionId", "");
      if (!_regionId) push("./no-data-result");
      regionId.value = _regionId;
      // if (location.href.indexOf("?") > 0) {
      //   window.history.replaceState(
      //     {},
      //     document.title,
      //     location.href.substring(0, location.href.indexOf("?"))
      //   );
      // }
    }

    /* -------------------------------------------------------------------------- */
    /*                                  Get Data                                  */
    /* -------------------------------------------------------------------------- */
    const region = ref<TRegion>();
    const { loading, data } = useGetOne<TRegion>("Region", REGION_FRAGMENT, {
      id: regionId.value
    });
    watchEffect(() => {
      if (data.value) {
        region.value = data.value;
        const certificates = chain(region.value)
          .cloneDeep()
          .get("certificates", [])
          .value();
        localStorage.setItem("certificates", JSON.stringify(certificates));
      }
    });
    const handleViewFull = (link: string) =>
      push({ name: ERoutesName.VIEW_IMAGE, query: { link } });

    return { loading, region, handleViewFull };
  }
});
